import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-892f60ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-nova-blue-600 text-base font-semibold mt-4 lessonPrimaryStyle breadCrumb__nav" }
const _hoisted_2 = { class: "my-2 grid grid-cols-12 w-full gap-4" }
const _hoisted_3 = {
  class: "col-span-4 side-panel bg-white postqueue-background border br-1 md:h-10/12-screen overflow-y-auto my-2 md:my-0",
  id: "category-list",
  style: {"scrollbar-width":"thin"}
}
const _hoisted_4 = { class: "main-section w-full col-span-8" }
const _hoisted_5 = { class: "post-details" }
const _hoisted_6 = { class: "video-post-body-container" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "h-full flex flex-col justify-center"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 1,
  class: "text-center flex flex-col items-center justify-center"
}
const _hoisted_12 = { class: "mt-5 flex justify-between items-center" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 0,
  class: "post-status-fetch-message flex justify-center md:justify-end items-center"
}
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "px-2" }
const _hoisted_18 = {
  key: 0,
  class: "post-body my-2 bg-white shadow rounded lesson-body-background overflow-y-auto"
}
const _hoisted_19 = {
  id: "post-description",
  class: "course-body-height p-4 custom-list-styling lessonSecondaryStyle lesson-body-description custom-word-break"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "mt-4" }
const _hoisted_24 = {
  key: 0,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NeueCategoryPostTree = _resolveComponent("NeueCategoryPostTree")!
  const _component_video_player = _resolveComponent("video-player")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_QuizStatusComponent = _resolveComponent("QuizStatusComponent")!
  const _component_AssignmentStatus = _resolveComponent("AssignmentStatus")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Lock01Icon = _resolveComponent("Lock01Icon")!
  const _component_UITextLgMedium = _resolveComponent("UITextLgMedium")!
  const _component_InstructorCard = _resolveComponent("InstructorCard")!
  const _component_CommentList = _resolveComponent("CommentList")!

  return (_openBlock(), _createElementBlock("div", {
    id: "post-details-container",
    class: _normalizeClass(["neue-template flex flex-col justify-center my-2 mx-auto", 
      _ctx.disableActions && _ctx.disableActions.pointerEvents === 'none' ? 'px-2' : ''
    ])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.product.title), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["progress rounded-full bg-gray-300 h-1 mb-1", _ctx.activePost.video ? 'lesson-video-progress' : 'lesson-progress'])
      }, [
        _createElementVNode("div", {
          id: "progress-completed",
          class: "bg-nova-blue-600 lesson-progress__color h-full w-full flex justify-center items-center rounded-full text-xs leading-none text-center",
          style: _normalizeStyle({
            width: `${_ctx.percentCompleted || 0}%`,
          })
        }, null, 4)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_NeueCategoryPostTree, {
            level: 0,
            "category-tree": _ctx.categoryTree,
            "completed-posts": _ctx.completedPostIds,
            isBackground: false,
            "active-post": _ctx.activePost,
            active: _ctx.toggleCategory,
            highlightBackground: _ctx.highlightBackground,
            highlightColor: _ctx.highlightColor,
            "post-indexes": _ctx.postIndexes,
            hasProductAccess: _ctx.hasProductAccess
          }, null, 8, ["category-tree", "completed-posts", "active-post", "active", "highlightBackground", "highlightColor", "post-indexes", "hasProductAccess"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.activePost.video && _ctx.hasProductAccess && _ctx.videoOptions)
              ? (_openBlock(), _createBlock(_component_video_player, {
                  key: 0,
                  id: "post-video",
                  class: "items-center mb-5",
                  loading: _ctx.fetchingPost,
                  video: _ctx.activePost && _ctx.activePost.video,
                  assetUrl: _ctx.activePost && _ctx.activePost.assetUrls,
                  options: _ctx.videoOptions,
                  trackTime: true,
                  videoCompletePercentage: _ctx.videoCompletePercentage,
                  "video-thumbnail": _ctx.activePost.posterImage,
                  onGetVideoTime: _ctx.getVideoTime,
                  onOnPause: _ctx.storeVideoProgress,
                  onOnEnded: _ctx.storeVideoProgress,
                  onOnPlay: _ctx.updateVideoPlay
                }, null, 8, ["loading", "video", "assetUrl", "options", "videoCompletePercentage", "video-thumbnail", "onGetVideoTime", "onOnPause", "onOnEnded", "onOnPlay"]))
              : (_ctx.activePost.audio)
                ? (_openBlock(), _createBlock(_component_audio_player, {
                    key: 1,
                    class: "player-height mb-4",
                    audio: _ctx.activePost.audio,
                    thumbnail: _ctx.activePost.posterImage,
                    post: _ctx.activePost,
                    product: _ctx.product
                  }, null, 8, ["audio", "thumbnail", "post", "product"]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["relative post-description-container post-body bg-white p-5 shadow rounded lesson-body-background overflow-y-auto custom-list-styling", [
                _ctx.expandPostData ? 'expanded-post-body' : 'post-body-description',
                !_ctx.hasProductAccess ? 'flex items-center justify-center' : '',
              ]]),
                    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showExpandIcon = true)),
                    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showExpandIcon = false))
                  }, [
                    (_ctx.hasProductAccess)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          (_ctx.currentPostContentType === _ctx.postContentType.quiz)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_component_QuizStatusComponent, {
                                  post: _ctx.activePost,
                                  categoryId: _ctx.categoryId,
                                  onChangeQuizStatus: _ctx.changeQuizStatus
                                }, null, 8, ["post", "categoryId", "onChangeQuizStatus"])
                              ]))
                            : (
                    _ctx.currentPostContentType === _ctx.postContentType.assignment
                  )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createVNode(_component_AssignmentStatus, {
                                    post: _ctx.activePost,
                                    categoryId: _ctx.categoryId,
                                    showRetakeButton: true,
                                    nextPost: _ctx.nextPostData,
                                    onGoToNextPost: _ctx.goToNextPost
                                  }, null, 8, ["post", "categoryId", "nextPost", "onGoToNextPost"])
                                ]))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  id: "post-description",
                                  class: "lessonSecondaryStyle lesson-body-description mb-5 custom-word-break custom-list-styling",
                                  innerHTML: _ctx.activePost.description
                                }, null, 8, _hoisted_10)),
                          (_ctx.showExpandIcon && _ctx.isExpandable)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 3,
                                class: "z-20 bottom-0 right-0 cursor-pointer expand-icon sticky float-right",
                                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.expandPostDetails && _ctx.expandPostDetails(...args)))
                              }, [
                                _createVNode(_component_BaseIcon, {
                                  name: "fullwindow",
                                  hwClass: "w-5 h-5"
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_Lock01Icon, { class: "h-20 w-20" }),
                          _createVNode(_component_UITextLgMedium, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.productAccessMessage), 1)
                            ]),
                            _: 1
                          })
                        ]))
                  ], 34))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToNextPost())),
                class: _normalizeClass(["border rounded py-2", 
                  _ctx.hasProductAccess && _ctx.isNextPostButtonEnabled
                    ? 'border-nova-blue-800'
                    : 'border-gray-400 cursor-not-allowed'
                ]),
                disabled: !_ctx.hasProductAccess || !_ctx.isNextPostButtonEnabled
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["lessonPrimaryStyle navigation-btn px-6 py-2", 
                    _ctx.hasProductAccess && _ctx.isNextPostButtonEnabled
                      ? 'text-nova-blue-800'
                      : 'text-gray-400'
                  ])
                }, "Next Lesson", 2)
              ], 10, _hoisted_13)
            ]),
            _createElementVNode("div", null, [
              (_ctx.fetchingPostCompletionStatus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, " Fetching post status.. "))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "pt-2 md:pt-0",
                    disabled: _ctx.disablePostCompletionButton || _ctx.isAdmin
                  }, [
                    _createElementVNode("button", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.togglePostCompletion())),
                      disabled: 
                    _ctx.disablePostCompletionButton ||
                    (_ctx.isAdmin && !_ctx.hasProductAccess)
                  ,
                      class: _normalizeClass(["py-2 px-2 rounded border flex justify-center items-center", _ctx.markCompletedBtnCss])
                    }, [
                      _createElementVNode("span", {
                        style: _normalizeStyle({ color: _ctx.postCompletedIcon })
                      }, [
                        _createVNode(_component_BaseIcon, {
                          name: "correct",
                          hwClass: "w-4 h-4"
                        })
                      ], 4),
                      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.postCompletionButtonText), 1)
                    ], 10, _hoisted_16)
                  ], 8, _hoisted_15))
            ])
          ]),
          (_ctx.activePost.video && _ctx.activePost.description)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  (_ctx.expandPost)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                        _createElementVNode("span", {
                          innerHTML: _ctx.activePost.description
                        }, null, 8, _hoisted_21),
                        _createElementVNode("span", {
                          class: "text-blue-500 cursor-pointer pb-2",
                          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.expandPostDescription && _ctx.expandPostDescription(...args)))
                        }, "Show Less")
                      ]))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: _ctx.postDescription
                      }, null, 8, _hoisted_22))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_InstructorCard, {
            instructor: _ctx.instructor,
            newYorkTheme: true
          }, null, 8, ["instructor"])
        ]),
        (_ctx.activePost.commentPermission !== 'hidden')
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createVNode(_component_CommentList, {
                productCommentPrivacy: _ctx.product.commentPrivacy,
                postId: _ctx.postId,
                productId: _ctx.productId,
                instructor: _ctx.instructor,
                commentPermission: _ctx.activePost.commentPermission,
                pendoPayload: { post: _ctx.activePost, product: _ctx.product, category: _ctx.category },
                commentIdToScroll: _ctx.commentIdToScroll
              }, null, 8, ["productCommentPrivacy", "postId", "productId", "instructor", "commentPermission", "pendoPayload", "commentIdToScroll"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}