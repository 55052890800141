import { NavigationItemType } from '@gohighlevel/clientportal-core'
import {
  Bell02Icon,
  Home05Icon,
  User02Icon,
  GraduationHat02Icon,
  BookClosedIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { h } from 'vue'
import { LIBRARY_MENU_ITEMS } from '@/helper/constants'

class Navigation {
  state = {
    navigationItems: [
      {
        name: 'Home',
        type: NavigationItemType.APP_ROUTE,
        routeName: ['LandingPage', 'LandingPage-v2'],
        showOnMobile: true,
        showOnWeb: false,
        icon: () => h(Home05Icon),
      },
      {
        name: LIBRARY_MENU_ITEMS.allCourses,
        routeName: ['library-v2', 'library-v2-v2'],
        current: true,
        type: NavigationItemType.APP_ROUTE,
        showOnMobile: true,
        showOnWeb: false,
        icon: () => h(GraduationHat02Icon),
        selectedIcon: () => h(GraduationHat02Icon),
      },
      {
        name: LIBRARY_MENU_ITEMS.myCourses,
        routeName: ['my-courses-library'],
        type: NavigationItemType.APP_ROUTE,
        showOnMobile: true,
        showOnWeb: false,
        icon: () => h(BookClosedIcon),
        selectedIcon: () => h(BookClosedIcon),
      },
      {
        name: 'Notifications',
        routeName: ['NotificationMobileView', 'NotificationMobileView-v2'],
        type: NavigationItemType.APP_ROUTE,
        showOnMobile: true,
        showOnWeb: false,
        icon: () => h(Bell02Icon),
      },
      {
        name: 'Profile',
        routeName: ['ProfileContainer', 'ProfileContainer-v2'],
        type: NavigationItemType.APP_ROUTE,
        showOnMobile: true,
        showOnWeb: false,
        icon: () => h(User02Icon),
      },
    ],
  }
}

export const navigation = new Navigation()
