<template>
  <div
    id="navigation-header"
    :class="
      !isCpAppInstalled
        ? `${getMobileTheme.primary} shadow py-3`
        : 'bg-white py-[4.5px] border-b'
    "
    class="sticky top-0 z-10 px-4 w-full"
  >
    <nav class="flex w-full items-center" :class="[`justify-${alignment}`]">
      <div class="w-full brand-logo-container">
        <div
          class="flex h-10 lg:h-auto relative flex-row-reverse lg:flex-row items-center justify-between w-full lg:w-auto"
        >
          <router-link
            v-if="!isCpAppInstalled"
            id="brandLogo"
            :to="{ name: libraryPath }"
            class="m-auto lg:m-0"
          >
            <img
              v-if="showLogo && brandLogoUrl"
              class="brand-logo h-10 w-auto"
              :src="replaceBucketUrlWithCdnUrl(brandLogoUrl)"
              alt="Logo"
            />
            <p class="font-semibold text-white lg:text-current m-0" v-else>
              Your Logo
            </p>
          </router-link>
          <div
            class="mr-2 absolute left-0 top-0 lg:relative flex items-center lg:hidden z-10"
          >
            <span
              v-if="backCallback"
              @click="backCallback"
              class="flex items-center py-2"
              :class="!isCpAppInstalled && 'text-white'"
            >
              <BaseIcon name="chevronleft" hwClass="w-4 h-4" />
              <span class="ml-2 text-lg">Back</span>
            </span>
            <div
              class="flex items-center py-2"
              v-else-if="isCpAppInstalled && !backCallback"
            >
              <UITextLgMedium
                v-if="activeLibrary === LIBRARY_MENU_ITEMS.allCourses"
              >
                All Courses
              </UITextLgMedium>
              <UITextLgMedium
                v-else-if="activeLibrary === LIBRARY_MENU_ITEMS.myCourses"
              >
                My Courses
              </UITextLgMedium>
            </div>
            <div
              v-else-if="!isCpAppInstalled"
              id="openMobileMenu"
              @click="open = true"
              class="inline-flex items-center justify-center py-2 rounded-md text-white lg:text-gray-400 transition duration-150 ease-in-out"
            >
              <BaseIcon name="hamburger" hwClass="w-6 h-6" />
            </div>
          </div>
          <div
            class="ml-2 absolute right-0 top-1/2 transform -translate-y-1/2 lg:relative flex items-center lg:hidden z-10"
            :class="!isCpAppInstalled && 'text-white'"
          >
            <UIButton
              v-if="isPortalActive && !isCpAppInstalled"
              id="client-portal-home"
              @click="redirectTo('home')"
              :quaternary="true"
              :circle="true"
            >
              <Home05Icon class="h-5 w-5 text-white" />
            </UIButton>
            <AppSwitcher
              v-if="isPortalActive && !isCpAppInstalled"
              @toggleAppLauncher="toggleAppLauncher"
              :appLauncherIsOpen="appLauncherIsOpen"
              :triggerColor="'text-white'"
            />
            <div @click="updateActiveLibrary(LIBRARY_MENU_ITEMS.search)">
              <router-link id="library-search" :to="{ name: libraryPath }">
                <span
                  class="flex items-center justify-center p-2 rounded-full w-10 h-10"
                  :class="!isCpAppInstalled ? 'text-white' : 'text-gray-900'"
                >
                  <BaseIcon name="search" />
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div
      v-if="!isCpAppInstalled"
      id="mobile-settings-menu"
      class="z-20 fixed top-0 left-0 inset-x-0 h-full transition ease-in-out duration-300 transform origin-top-right lg:hidden"
      :class="open ? '-translate-x-0' : '-translate-x-full'"
    >
      <div class="flex relative shadow-md w-full h-full">
        <div
          class="h-full max-w-sm flex flex-col justify-between w-2/3 bg-white shadow-xs overflow-hidden z-10"
        >
          <div>
            <div class="px-2 pt-4 flex items-center justify-between">
              <!-- <div class="mobile-brand-logo-container">
                <img
                  v-if="brandLogoUrl"
                  class="brand-logo h-10 w-auto"
                  :src="replaceBucketUrlWithCdnUrl(brandLogoUrl)"
                  alt
                />
                <p class="font-semibold" v-else>Your Logo</p>
              </div> -->
              <div class="ml-auto">
                <button
                  id="closeMobileMenu"
                  @click="open = false"
                  type="button"
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                >
                  <BaseIcon name="cross" hwClass="w-4 h-4" />
                </button>
              </div>
            </div>
            <div
              class="px-2 pt-2 pb-3 flex flex-col items-center justify-center border-b"
            >
              <user-avatar
                v-if="userImage"
                class="h-20 w-20"
                :src="userImage"
              />
              <span v-else>
                <BaseIcon name="userprofile" />
              </span>
              <div
                class="py-2 text-base font-medium text-gray-700 w-full truncate text-center"
              >
                {{ username }}
              </div>
              <div @click="open = !open">
                <router-link
                  :to="{ name: 'profile-settings' }"
                  class="text-sm"
                  :class="getMobileTheme.primaryText"
                  >Edit Profile</router-link
                >
              </div>
            </div>
            <div class="px-2 pt-2 pb-3">
              <div @click="open = !open">
                <router-link
                  :to="{ name: 'password-settings' }"
                  class="block px-3 py-2 flex justify-between items-center rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <span>Password</span>
                  <BaseIcon name="chevronright" hwClass="w-4 h-4" />
                </router-link>
              </div>
              <div @click="open = !open">
                <router-link
                  v-if="!isAdmin"
                  :to="{ name: 'purchase-history' }"
                  class="mt-1 flex justify-between items-center block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <span>Purchase History</span>
                  <BaseIcon name="chevronright" hwClass="w-4 h-4" />
                </router-link>
              </div>
              <div
                v-if="
                  !isPWAInstalled &&
                  !isPortalActive &&
                  pwaAppSettings.pwaEnabled
                "
                @click="open = !open"
                class="mt-4 flex flex-col justify-between items-center block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
              >
                <div class="pwa-app-logo mb-3">
                  <img :src="pwaAppSettings.logo" alt="app-logo" class="w-12" />
                </div>
                <div class="pwa-installation-trigger text-center mb-2">
                  Add
                  <span class="font-bold">
                    {{ pwaAppSettings && pwaAppSettings.name }}
                  </span>
                  to your home screen
                </div>
                <button
                  @click="openPWAInstallAlert"
                  class="py-1"
                  :class="getMobileTheme.primaryText"
                >
                  <BaseIcon name="install" />
                </button>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <a
              @click="signOut"
              href="#"
              class="block flex items-center w-full px-5 py-3 text-base font-medium text-danger bg-gray-50"
              :class="getMobileTheme.primaryText"
            >
              <BaseIcon name="exit" hwClass="w-5 h-5" />
              <span class="ml-2">Log Out</span>
            </a>
          </div>
        </div>
        <!-- Background sheen for mobile only -->
        <div
          class="w-full absolute right-0 top-0 transition-transform ease duration-100 bg-black-o-55 h-full"
          :class="open ? '-translate-y-0' : '-translate-y-full'"
          @click="open = !open"
        ></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import firebase from 'firebase'
import 'firebase/auth'
import { userAvatarImage, LIBRARY_MENU_ITEMS } from '../../helper/constants'
import UserAvatar from '@/components/common/UserAvatar.vue'
import { adminMode } from '../../helper/permission.helper'
import { getMobileTheme, isClientPortal } from '../../helper'
import { getContactId } from '@/services'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { isAppInstalled, redirectTo } from '@/helper'
import AppSwitcher from '@/components/common/AppSwitcher.vue'
import { UIButton, UITextLgMedium } from '@gohighlevel/ghl-ui'
import { Home05Icon } from '@gohighlevel/ghl-icons/24/outline'
import { Contacts } from '@/helper/restAgent'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { isPwa } from '@gohighlevel/clientportal-core'

export default defineComponent({
  components: {
    UserAvatar,
    BaseIcon,
    UIButton,
    UITextLgMedium,
    AppSwitcher,
    Home05Icon,
  },
  props: {
    logo: {
      type: String,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    alignment: {
      type: String,
      default: 'between',
    },
    backCallback: {
      type: Function,
    },
    locationId: String,
  },
  data() {
    return {
      open: false,
      openSettings: false,
      headerRoot: {
        'product-overview': 'navigation-header',
        'post-overview': 'lesson-navigation-header',
      },
      headerContainer: {
        'product-overview': 'header',
        'post-overview': 'lesson-header',
      },
      username: '',
      LIBRARY_MENU_ITEMS,
      appLauncherIsOpen: false,
    }
  },
  computed: {
    brandLogoUrl(): string {
      return this.logo || this.$store.state.logoUrl
    },
    userImage(): string {
      return this.$store.getters.userAvatar || userAvatarImage
    },
    isAdmin() {
      return adminMode()
    },
    libraryPath(): string {
      return 'library-v2'
    },
    pwaAppSettings() {
      return this.$store.getters.pwaAppSettings
    },
    isPWAInstalled() {
      return isAppInstalled()
    },
    isPortalActive() {
      return isClientPortal()
    },
    activeLibrary() {
      return this.$store.getters.activeLibrary
    },
    isCpAppInstalled(): boolean {
      return this.$store.getters.appFlags.isCpAppInstalled
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  mounted() {
    this.getUsername()
  },
  methods: {
    toggleAppLauncher() {
      this.appLauncherIsOpen = !this.appLauncherIsOpen
    },
    updateActiveLibrary(val: string) {
      this.$store.commit('UPDATE_ACTIVE_LIBRARY', val)
      window.scrollTo(0, 0)
    },
    signOut() {
      this.$cookies.remove('cat')
      if (localStorage) {
        try {
          localStorage.removeItem('cat')
        } catch (error) {
          // patch
        }
      }
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: 'login' })
        })
    },
    async getUsername() {
      const contactId = getContactId()
      if (!contactId) {
        throw new Error(`Invalid contact id ${contactId}`)
      }

      const { firstName, lastName } = await Contacts.getContactById(
        this.locationId,
        contactId
      )
      this.username = [firstName, lastName].filter((d) => d).join(' ')
    },
    openPWAInstallAlert() {
      this.$store.commit('SET_TRIGGER_PWA_INSTALL', true)
    },
    redirectTo,
    replaceBucketUrlWithCdnUrl,
    isPwa,
  },
})
</script>
