<template>
  <router-view></router-view>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  initialize,
  saveUserId,
  saveContactId,
  saveFeatureFlag,
  getContactId,
} from '@/services'
import { fetchAdminCat, fetchCat } from '@/helper/permission.helper'
import LoginService from '../services/LoginService'
import { enableAnalytics } from '@gohighlevel/clientportal-core'
import { isClientPortal, redirectTo } from '@/helper'

export default defineComponent({
  beforeMount() {
    try {
      const cat = fetchCat() || fetchAdminCat()

      if (!cat) return

      const {
        locationId,
        location_id,
        userId,
        external_user_id,
        externalUserId,
        contact_id,
        contactId,
        featureFlag,
      } = cat

      if (locationId || location_id) {
        initialize(locationId || location_id)
      }

      if (externalUserId || external_user_id || userId) {
        saveUserId(externalUserId || external_user_id || userId)
      }

      if (contactId || contact_id) {
        saveContactId(contactId || contact_id)
      }

      if (featureFlag) {
        saveFeatureFlag(featureFlag)
      }
    } catch (error) {
      if (isClientPortal()) return redirectTo('login')
      this.$router.push({ name: 'login' })
    }
  },
  mounted() {
    this.fetchUserAvatar()
    const contactId = getContactId()
    try {
      if (contactId) {
        const appName = isClientPortal() ? 'Courses' : 'Client Portal'
        enableAnalytics(contactId, appName)
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async fetchUserAvatar() {
      try {
        const avatar = await LoginService.getAvatar()
        this.$store.commit('SAVE_AVATAR', avatar)
      } catch (error) {
        console.error('Error while fetching user avatar --> ', error)
      }
    },
  },
})
</script>
