import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "h-8 w-8 bg-lock flex items-center justify-center rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["post-list-item py-4 justify-between items-center cursor-pointer", 
      _ctx.newMobileScreens
        ? 'px-4 my-2 rounded-lg shadow bg-category-bg flex w-full'
        : 'flex'
    ]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPost && _ctx.openPost(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "w-32 post-image",
            src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.posterImage)
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass(["items-center", [
          _ctx.getMobileTheme.primaryText,
          _ctx.newMobileScreens && !_ctx.isBuilder ? 'flex' : 'block mx-5',
        ]])
      }, [
        (_ctx.showAssessmentIcon)
          ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              name: "assessments",
              hwClass: "w-3 h-3"
            }))
          : (_ctx.newMobileScreens && !_ctx.isBuilder)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(["inline-flex items-center", _ctx.getMobileTheme.primaryText])
              }, [
                _createVNode(_component_BaseIcon, {
                  name: "play",
                  hwClass: "w-3 h-3"
                })
              ], 2))
            : _createCommentVNode("", true),
        (_ctx.available)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["font-sans post-title flex-1 text-lg hover:text-blue-600 hover:underline", [
            (_ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens) &&
            _ctx.$route.name.includes('product-overview')
              ? 'primaryStyle lesson-title'
              : 'text-gray-600',
            (_ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens) &&
            _ctx.$route.name.includes('category-overview')
              ? 'text-blue-600'
              : '',
            _ctx.newMobileScreens ? 'mx-3' : 'mx-0',
          ]])
            }, _toDisplayString(_ctx.post.title && _ctx.post.title.length > _ctx.headingLimit
              ? _ctx.post.title.slice(0, _ctx.headingLimit) + '...'
              : _ctx.post.title), 3))
          : (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(["post-title flex-1 mx-3 lg:mx-5 text-lg text-gray-500", 
            _ctx.$route.name.includes('product-overview') && !_ctx.newMobileScreens
              ? 'primaryStyle lesson-title'
              : ''
          ])
            }, _toDisplayString(_ctx.post.title && _ctx.post.title.length > _ctx.headingLimit
              ? _ctx.post.title.slice(0, _ctx.headingLimit) + '...'
              : _ctx.post.title), 3))
      ], 2)
    ]),
    (_ctx.completed)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "h-5",
          src: _ctx.checkIcon,
          alt: "Completed"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.isLocked && _ctx.newMobileScreens)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_BaseIcon, { name: "lockfilled" })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}