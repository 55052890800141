<template>
  <div :class="isPortalNavbar ? 'pt-14' : ''">
    <div
      v-if="fetching || fetchingPost || fetchingDetails"
      class="flex justify-center items-center h-screen"
    >
      <UISpinner size="large" />
    </div>
    <div
      v-else-if="isCategoryLocked || parentCategoryLocked || isActivePostLocked"
      class="w-full flex flex-col justify-center items-center"
    >
      <img class="h-20 py-4" :src="lockIcon" alt="Lock Category" />
      <div class="text-4xl extrabold text-center">This Lesson is Locked!</div>
      <div class="text-xl text-center lg:text-left py-4 extrabold">
        To gain access to this {{ lockedOverlayContentText }} please complete.
      </div>
      <div v-if="lockedPost">
        <button
          @click="redirectToPost(lockedPost.categoryId, lockedPost.id)"
          class="my-4 rounded text-white lg:px-8 lg:py-4 md:px-6 md:py-3 px-4 py-2 bg-gray-800 cursor-pointer"
          v-if="showLockedButton && lockedPost && lockedPost.title"
        >
          {{ `Post Name` && `Post: ${lockedPost.title}` }}
        </button>
      </div>
      <div v-if="lockedCategory">
        <button
          @click="redirectToPost(lockedCategory.id)"
          class="my-4 rounded text-white lg:px-8 lg:py-4 md:px-6 md:py-3 px-4 py-2 bg-gray-800 cursor-pointer"
          v-if="showLockedButton && lockedCategory && lockedCategory.title"
        >
          {{ `Category Name` && `Category: ${lockedCategory.title}` }}
        </button>
      </div>
      <div v-if="!showLockedButton">
        <UISpinner size="small" />
      </div>
    </div>
    <div
      v-else
      class="max-w-6xl w-full mx-auto md:px-0"
      :class="[
        newMobileScreens ? '' : 'my-4 px-4',
        selectedTheme !== 'Neue' && !newMobileScreens
          ? 'max-w-6xl my-4 px-4'
          : '',
      ]"
    >
      <component
        v-if="routeName.includes('post-overview')"
        :is="`${selectedTheme}LessonTheme`"
        :product="product"
        :instructor="instructor"
        :activePost="activePost"
        :category="category"
        :videoOptions="videoOptions"
        :videoCompletePercentage="videoCompletePercentage"
        :nextPost="nextPost"
        :playlist="playlist"
        :lessonButton="lessonButton"
        :isAdmin="isAdmin"
        :categoryTree="categoryTree"
        :nextLesson="nextLesson"
        :disableActions="disableActions"
        :fetchingPost="fetchingPost"
        :nextCategoryId="nextCategoryId"
        :completedPostIds="completedPostIds"
        :markCompletedBtnCss="markCompletedBtnCss"
        :postMarkedAsCompleted="postMarkedAsCompleted"
        :postCompletionButtonText="postCompletionButtonText"
        :disablePostCompletionButton="disablePostCompletionButton"
        :fetchingPostCompletionStatus="fetchingPostCompletionStatus"
        :totalPosts="totalPosts"
        :completedPosts="completedPosts"
        @togglePostCompletion="togglePostCompletion"
        @updateBackData="updateBackData"
        @storeVideoProgress="storeVideoProgress"
        @updateVideoTime="updateVideoTime"
        @updateVideoPlay="updateVideoPlay"
        @storeAudioProgress="storeAudioProgress"
        @updateAudioTime="updateAudioTime"
        @updateAudioPlay="updateAudioPlay"
        :hasProductAccess="hasProductAccess"
      />
      <router-view
        v-else
        :product="product"
        :activePost="activePost"
        :category="category"
        :categoryTree="categoryTree"
        :nextPost="nextPost"
        :playlist="playlist"
        :instructor="instructor"
        :fetchingPostCompletionStatus="fetchingPostCompletionStatus"
        :disablePostCompletionButton="disablePostCompletionButton"
        :isAdmin="isAdmin"
        :isUngradedAssignment="isUngradedAssignment"
        :markCompletedBtnCss="markCompletedBtnCss"
        :postCompletionButtonText="postCompletionButtonText"
        @updateBackData="updateBackData"
        @togglePostCompletion="togglePostCompletion"
      ></router-view>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import ClassicLessonTheme from '@/components/posts/templates/ClassicLessonTheme.vue'
import NewYorkLessonTheme from '@/components/posts/templates/NewYorkLessonTheme.vue'
import MobileNewYorkLessonTheme from '@/components/posts/templates/MobileNewYorkLessonTheme.vue'
import NeueLessonTheme from '@/components/posts/templates/NeueLessonTheme.vue'
import {
  CategoryService,
  PostService,
  UserPostCompletionService,
  UserProductTrackingService,
  UserPurchaseService,
  VideoService,
  UserPostTrackingService,
  AssessmentStatusService,
  AssignmentService,
} from '../services'
import Post, { PostVisibility } from '../models/Post'
import Assignment from '../models/Assignment'
import {
  createCategoryPostTree,
  computeNextPost,
  findCategory,
  isClientPortal,
  checkNodeIsCompleted,
} from '@/helper'
import Category from '../models/Category'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '../models/CategoryTreeNode'
import { lockIcon, SOURCES } from '../helper/constants'
import { adminMode, fetchAdminCat, fetchCat } from '@/helper/permission.helper'
import { generateToken } from '../../http-common'
import UISpinner from '@/components/common/UISpinner.vue'
import { getStreamingUrl } from '@/helper/filter'
import { PostContentType } from '@/models/Post'
import { AssessmentStatusOptions } from '@/models/AssessmentStatus'
import {
  trackCustomGAEvents,
  GAEventTypes,
} from '@gohighlevel/clientportal-core'
import { isMobileTablet } from '@/helper/device_info'

export default defineComponent({
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    productStarted: Boolean,
    fetching: Boolean,
    disableActions: {
      type: Object,
    },
    selectedTheme: {
      type: String,
      default: 'Classic',
    },
    allCompletedPostsData: {
      type: Object,
      default: null,
    },
  },
  components: {
    UISpinner,
    ClassicLessonTheme,
    NewYorkLessonTheme,
    MobileNewYorkLessonTheme,
    NeueLessonTheme,
  },
  computed: {
    lockedOverlayContentText(): string {
      return this.activePost &&
        (this.activePost.contentType === this.postContentType.video ||
          this.activePost.contentType === this.postContentType.audio)
        ? 'lesson'
        : 'assessment'
    },
    routeName(): string {
      return this.$route.name
    },
    productId(): string {
      return this.$route.params.id || this.product.id
    },
    categoryId(): string {
      return this.$route.params.category_id || this.category.id
    },
    postId(): string {
      return this.$route.params.post_id
    },
    postCompletionButtonText(): string {
      if (this.disablePostCompletionButton) {
        if (
          this.activePost.contentType === this.postContentType.quiz ||
          this.activePost.contentType === this.postContentType.assignment
        ) {
          return this.lessonButton?.content
            ? this.lessonButton.content
            : 'Mark As Complete'
        }
        return this.postMarkedAsCompleted
          ? 'Marking as Incomplete'
          : 'Completing..'
      } else {
        return this.postMarkedAsCompleted
          ? 'Completed'
          : this.lessonButton?.content
          ? this.lessonButton.content
          : 'Mark As Complete'
      }
    },
    lessonSettings(): any {
      return this.product.customizations?.lessonSettings
    },
    lessonBody(): any {
      return this.lessonSettings?.sections?.lessonBody
    },
    lessonButton(): any {
      return this.lessonBody?.button || null
    },
    nextLesson(): string {
      return this.lessonBody?.nextLesson
    },
    isAdmin() {
      return adminMode()
    },
    markCompletedBtnCss() {
      if (this.disablePostCompletionButton || this.isAdmin) {
        return 'lesson-body__btn cursor-not-allowed'
      } else if (this.postMarkedAsCompleted) {
        return 'hover:bg-green-500 text-green-700 border-green-500 hover:text-white hover:border-transparent'
      }
      return 'lesson-body__btn hover:bg-blue-500 text-blue-700 border-blue-500 hover:text-white hover:border-transparent'
    },
    videoOptions() {
      if (!this.activePost.video?.id) {
        return null
      }
      const cat = fetchCat() || fetchAdminCat()
      let locationId = null

      if (cat && cat.locationId) {
        locationId = cat.locationId
      } else if (cat) {
        locationId = cat.location_id
      }
      let transcodeUrl
      let transcodingUrl = getStreamingUrl(this.activePost?.video?.url)
      if (this.activePost?.assetUrls?.transcodedUrl) { 
        transcodingUrl = getStreamingUrl(this.activePost.assetUrls.url)
        transcodeUrl = `${transcodingUrl}/${this.activePost.assetUrls.transcodedUrl}.urlset/master.m3u8?token=${this.token}`
      } else if (this.activePost?.video?.videoFormats) {
        const videoFormats = this.activePost.video.videoFormats.join(',')
        transcodeUrl = `${transcodingUrl}/${locationId}/videos/${this.activePost.video.id}_,${videoFormats},00k.mp4.urlset/master.m3u8?token=${this.token}`
      } else if (this.activePost?.video?.hdTranscoded) {
        transcodeUrl = `${transcodingUrl}/${locationId}/videos/${this.activePost.video.id}_,53,32,16,00k.mp4.urlset/master.m3u8?token=${this.token}`
      } else {
        transcodeUrl = `${transcodingUrl}/${locationId}/videos/${this.activePost.video.id}_,32,16,9,6,00k.mp4.urlset/master.m3u8?token=${this.token}`
        const transcodingErrorPayload = {
          deviceName: navigator.userAgent,
          deviceType: isMobileTablet() ? 'mobile' : 'desktop',
        }
        VideoService.reTranscodeVideo(
          this.activePost.video.id,
          transcodingErrorPayload
        )
      }
      return {
        sources: [
          {
            src: transcodeUrl,
            type: 'application/x-mpegURL',
          },
        ],
      }
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isCategoryLocked(): boolean {
      return this.category.visibility === 'locked' && this.category.isLocked
    },
    postIdsCompleted(): Set<string> {
      return this.allCompletedPostsData?.completedPostIds
        ? this.allCompletedPostsData?.completedPostIds
        : new Set()
    },
    allCompletedPosts(): Array<any> {
      return this.allCompletedPostsData?.posts
        ? this.allCompletedPostsData?.posts
        : []
    },
    hasProductAccess(): boolean {
      return toRaw(this.product)?._data?.hasProductAccess
    },
    isPortalNavbar(): boolean {
      return (
        isClientPortal() &&
        !this.$store.getters.newMobileScreens &&
        this.source !== SOURCES.communities
      )
    },
    isUngradedAssignment(): boolean {
      return (
        this.assignment.ungradedAssignment === true &&
        this.activePost.contentType === this.postContentType.assignment
      )
    },
    nextPost() {
      const computedPost = computeNextPost(
        this.categoryTree,
        this.postIdsCompleted
      )
      return computedPost ? computedPost.node : null
    },
  },
  async mounted() {
    this.fetchingDetails = true
    await Promise.all([
      this.fetchPost(this.postId),
      this.fetchCategoryAndPosts(this.categoryId),
      this.$emit('fetchAllCompletedPosts'),
    ])
    this.token = await generateToken()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    this.createCategoryTree()
    await this.fetchCompletionData()
    this.fetchNextCategory(this.categoryId)
    this.updateBackData({
      name: 'category-overview',
      params: { id: this.productId, category_id: this.categoryId },
    })
    this.handleLocking()
    this.fetchingDetails = false
    this.isVideoProgressUpdated = false

    /* For IOS devices, to detect blur event */
    if (this.newMobileScreens) {
      document.body.onclick = () => ({})
      window.addEventListener('blur', (e) => {
        e.preventDefault()
        this.updateVideoProgress()
        this.updateAudioProgress()
      })
      window.addEventListener('pagehide', () => {
        this.updateVideoProgress()
        this.updateAudioProgress()
      })
    }

    window.addEventListener('beforeunload', () => {
      this.updateVideoProgress()
      this.updateAudioProgress()
    })
  },
  data() {
    return {
      category: {} as Category,
      parentCategory: {} as Category,
      activePost: {} as Post,
      lockedPost: {} as Post,
      lockedCategory: {} as Category,
      assignment: {} as Assignment,
      fetchingPost: false,
      fetchingPostCompletionStatus: true,
      postMarkedAsCompleted: false,
      disablePostCompletionButton: false,
      completedPostIds: new Set() as Set<string>,
      completedCategoryIds: new Set() as Set<string>,
      playlist: [] as Array<CategoryTreeNode>,
      categoryTree: [],
      nextCategoryId: '',
      lockIcon,
      token: '',
      totalPosts: 0,
      completedPosts: 0,
      isActivePostLocked: false,
      showLockedButton: false,
      fetchingDetails: true,
      assessmentStatus: Object,
      parentCategoryLocked: false,
      videoCompletePercentage: 0,
      audioCompletePercentage: 0,
      isVideoProgressUpdated: false,
      isAudioProgressUpdated: false,
      videoTime: [],
      audioTime: [],
      source: this.$route.query.source,
      postContentType: PostContentType,
      assessmentStatusOptions: AssessmentStatusOptions,
    }
  },
  methods: {
    resetData() {
      this.videoCompletePercentage = 0
      this.postMarkedAsCompleted = false
      this.showLockedButton = false
      this.lockedPost = {} as Post
      this.lockedCategory = {} as Category
      this.disablePostCompletionButton = false
    },
    async redirectToPost(categoryId: string, postId?: string) {
      const params: { [key: string]: string } = {
        id: this.productId,
        category_id: categoryId,
      }

      if (postId) {
        params.post_id = postId
      }

      this.$router.push({
        name: postId ? 'post-overview' : 'category-overview',
        params: params,
        query: this.$route.query,
      })
    },
    async fetchLockedDetails() {
      const promiseArray = []
      if (this.activePost.lockedByPost) {
        promiseArray.push(this.fetchedLockedPost(this.activePost.lockedByPost))
      }
      if (this.activePost.lockedByCategory) {
        promiseArray.push(
          this.fetchedLockedCategory(this.activePost.lockedByCategory)
        )
      }

      if (promiseArray.length > 0) {
        await Promise.all(promiseArray)
      }
    },
    async fetchCategoryAndPosts(categoryId: string) {
      this.fetchingPost = true
      this.category = await UserPurchaseService.getCategory(categoryId, {
        published_posts: true,
        product_id: this.productId,
      })

      if (!this.category.available) {
        this.$router.push({
          name: 'product-overview',
          params: {
            id: this.productId,
          },
          query: this.$route.query,
        })
      }

      // const subCategories = await CategoryService.findAll({
      //   parent_category: categoryId,
      //   published_posts: true,
      //   visibility: CategoryVisibility.published
      // })

      const subCategories = await UserPurchaseService.getCategories({
        product_id: this.productId,
        parent_category: categoryId,
      })

      const postNodes = this.category.posts.map(
        (p: Post) =>
          new CategoryTreeNode(p, CategoryTreeNodeType.Post, categoryId)
      )
      const subCategoryNodes = subCategories.map(
        (c: Category) =>
          new CategoryTreeNode(c, CategoryTreeNodeType.Category, categoryId)
      )

      this.playlist = [...postNodes, ...subCategoryNodes].sort(
        (a: CategoryTreeNode, b: CategoryTreeNode) =>
          a.sequenceNo - b.sequenceNo
      )
      this.fetchingPost = false
    },
    async handleLocking() {
      this.showLockedButton = false
      const parentCategoryNode = this.categoryTree.find((e) => {
        if (
          this.category &&
          this.category.parentCategory &&
          e.id === this.category.parentCategory
        ) {
          return true
        }
      })

      if (parentCategoryNode) {
        this.parentCategory = parentCategoryNode?.node?._data
        if (
          this.parentCategory &&
          this.parentCategory.visibility === 'locked' &&
          this.parentCategory.lockedByCategory
        ) {
          const parentLockedByCategory = findCategory(
            this.categoryTree,
            this.parentCategory.lockedByCategory
          )

          if (parentLockedByCategory) {
            const isParentCompleted: boolean = checkNodeIsCompleted(
              this.completedPostIds,
              parentLockedByCategory
            )
            this.parentCategoryLocked = !isParentCompleted
          }
        }
        if (
          this.parentCategory &&
          this.parentCategory.visibility === 'locked' &&
          this.parentCategory.lockedBy
        ) {
          const isParentPostCompleted: boolean = this.completedPostIds.has(
            this.parentCategory.lockedBy
          )
          this.parentCategoryLocked =
            this.parentCategoryLocked || !isParentPostCompleted
        }
      }

      if (this.category.visibility === 'locked' && this.category.isLocked) {
        await this.fetchedLockedPost(this.category.lockedBy)
        await this.fetchedLockedCategory(this.category.lockedByCategory)
      } else if (this.parentCategoryLocked) {
        await this.fetchedLockedPost(this.parentCategory.lockedBy)
        await this.fetchedLockedCategory(this.parentCategory.lockedByCategory)
      } else if (this.isActivePostLocked) {
        await this.fetchLockedDetails()
      }
      this.showLockedButton = true
    },
    async fetchedLockedPost(postId: string) {
      if (!postId) return
      this.showLockedButton = false
      try {
        this.lockedPost = await PostService.findById(postId)
        return this.lockedPost
      } catch (error) {
        console.error('Error while fetching locked post --> ', error)
      } finally {
        this.showLockedButton = true
      }
    },
    async fetchedLockedCategory(categoryId: string) {
      if (!categoryId) return
      this.lockedCategory = await CategoryService.findById(categoryId)
    },
    async fetchPost(postId: string) {
      this.fetchingPost = true
      try {
        this.activePost = await PostService.findById(postId)
        if (
          this.activePost.visibility == PostVisibility.locked &&
          !this.category.isLocked
        ) {
          const isPostLocked = await PostService.isPostLocked(postId)
          this.isActivePostLocked = isPostLocked.isLocked
        } else {
          this.isActivePostLocked = false
        }
        if (
          this.activePost.contentType !== this.postContentType.video &&
          this.activePost.contentType !== this.postContentType.audio
        ) {
          await this.fetchLastSubmission()
        }
        if (!this.category.isLocked && !this.isActivePostLocked) {
          // if it's locked the post can't start
          await this.postContentOpened() // whenever a new post is fetched, assume it's opened
        }
        if (this.activePost.contentType === this.postContentType.assignment) {
          this.fetchAssignment()
        }
      } catch (error) {
        console.error('Error while fetching post --> ', error)
      } finally {
        this.fetchingPost = false
      }
    },
    async fetchAssignment() {
      try {
        const assignmentResponse = await AssignmentService.findById(
          this.activePost.id
        )
        this.assignment = toRaw(assignmentResponse)
        if (this.assignment.ungradedAssignment === true) {
          this.disablePostCompletionButton = false
        }
      } catch (error) {
        console.error('Error fetching assignment', error)
      }
    },
    async fetchLastSubmission() {
      try {
        const fetchedSubmission =
          await AssessmentStatusService.getLastSubmissionByPostId(this.postId)
        this.assessmentStatus = fetchedSubmission.data.lastSubmission
        if (
          !this.assessmentStatus ||
          this.assessmentStatus.status ===
            this.assessmentStatusOptions.failed ||
          this.assessmentStatus.status ===
            this.assessmentStatusOptions.processing
        ) {
          this.disablePostCompletionButton = true
        }
      } catch (error) {
        console.error('Error while fetching last submission --> ', error)
      }
    },
    async togglePostCompletion() {
      try {
        if (this.disablePostCompletionButton) return // adding this to handle multiple post completion toggles

        this.disablePostCompletionButton = true

        if (this.postMarkedAsCompleted) {
          await this.markPostAsIncomplete()
          this.postMarkedAsCompleted = false
        } else {
          if (!this.productStarted) {
            UserProductTrackingService.trackProductStart(this.productId)
          }

          await this.markPostAsCompleted()
          this.disablePostCompletionButton = false
          this.postMarkedAsCompleted = true
        }
      } catch (error) {
        console.error('error while changing post completion status --> ', error)
      } finally {
        this.disablePostCompletionButton = false
      }
    },
    async createCategoryTree() {
      const categories = await UserPurchaseService.getCategories({
        product_id: this.productId,
      })
      this.categoryTree = createCategoryPostTree(null, categories)
    },
    async markPostAsCompleted() {
      const data = await UserPostCompletionService.markPostAsCompleted({
        postId: this.postId,
        productId: this.productId,
        percentage: 100,
      })
      trackCustomGAEvents(GAEventTypes.LESSON_COMPLETED, {
        locationId: this.locationId,
        postId: this.postId,
      })

      this.activePost.postCompletedRef = data.id

      const completedPosts = this.completedPostIds
      completedPosts.add(this.activePost.id)
      this.completedPostIds = new Set(completedPosts)

      if (this.activePost.contentType === this.postContentType.video) {
        this.videoCompletePercentage = 100
        this.updateVideoTime([this.videoTime[1], this.videoTime[1]])
      }
      if (this.activePost.contentType === this.postContentType.audio) {
        this.audioCompletePercentage = 100
        this.updateAudioTime([this.audioTime[1], this.audioTime[1]])
      }

      this.$emit('updateCompletedPostData', this.completedPostIds, [
        ...this.allCompletedPosts,
        { ...this.activePost, percentage: 100 },
      ])
    },
    async markPostAsIncomplete() {
      if (this.activePost.postCompletedRef) {
        await UserPostCompletionService.markPostAsIncomplete(
          this.activePost.postCompletedRef
        )
      }

      this.activePost.postCompletedRef = null

      const completedPosts = this.completedPostIds
      completedPosts.delete(this.activePost.id)
      this.completedPostIds = new Set(completedPosts)
      if (this.activePost.contentType === this.postContentType.video) {
        this.videoCompletePercentage = 0
        this.updateVideoTime([0, this.videoTime[1]])
      }
      if (this.activePost.contentType === this.postContentType.audio) {
        this.audioCompletePercentage = 0
        this.updateAudioTime([0, this.audioTime[1]])
      }
      this.$emit('updateCompletedPostData', this.completedPostIds, [
        ...this.allCompletedPosts.filter(({ id }) => id !== this.activePost.id),
      ])
    },
    async fetchCompletionData(updatedPostId: string) {
      await Promise.all([
        this.fetchCompletedPosts(updatedPostId),
        this.fetchNoOfPublishedPosts(),
      ])
    },
    async fetchCompletedPosts(updatedPostId: string) {
      try {
        this.fetchingPostCompletionStatus = true

        if (updatedPostId) await this.$emit('fetchAllCompletedPosts')
        this.completedPostIds = this.postIdsCompleted
        this.completedPosts = this.postIdsCompleted.size

        const currentPost = this.allCompletedPosts.find(
          ({ postId }) =>
            postId === (updatedPostId ? updatedPostId : this.activePost.id)
        )
        if (currentPost) {
          this.videoCompletePercentage = currentPost.percentage
            ? currentPost.percentage
            : 0
        } else {
          this.videoCompletePercentage = 0
        }
        this.updateCurrentPostCompletionStatus(currentPost)
      } catch (error) {
        console.error('Failed to fetch completed posts: ', error)
      } finally {
        this.fetchingPostCompletionStatus = false
      }
    },
    updateCurrentPostCompletionStatus(currentPost: any) {
      if (currentPost) {
        this.activePost.postCompletedRef = currentPost.id
        this.postMarkedAsCompleted = currentPost.percentage === 100
      } else {
        this.postMarkedAsCompleted = false
      }
    },
    async fetchNoOfPublishedPosts() {
      const publishedResp = await PostService.noOfPublishedPosts({
        product_id: this.productId,
      })

      this.totalPosts = publishedResp.count
    },
    async fetchNextCategory(categoryId: string) {
      const nextCategoryId = await CategoryService.findNextCategory(categoryId)
      console.log('found next category --> ', nextCategoryId)
      this.nextCategoryId = nextCategoryId
    },

    async checkIfPostCompleted(postId: string) {
      if (!this.hasProductAccess) return
      try {
        this.fetchingPostCompletionStatus = true

        const data = await UserPostCompletionService.checkIfPostCompleted(
          postId
        )

        if (data && data.length && data[0].percentage === 100) {
          this.activePost.postCompletedRef = data[0].id
          this.postMarkedAsCompleted = true
        } else {
          this.postMarkedAsCompleted = false
        }
      } catch (error) {
        console.error('Error while checking if post is completed --> ', error)
      } finally {
        this.fetchingPostCompletionStatus = false
      }
    },

    async postContentOpened() {
      if (!this.hasProductAccess) return
      if (this.activePost !== undefined && this.activePost !== null) {
        const { categoryId, productId } = this.activePost
        try {
          await UserPostTrackingService.checkIfPostStarted(
            this.postId || this.activePost.id,
            categoryId,
            productId
          )
        } catch (error) {
          console.log('Error:', JSON.stringify(error?.message))
          if (error?.config?.url) {
            console.log('url', error?.config?.url)
          }
        }
      }
    },
    updateVideoProgress() {
      if (this.isAdmin || !this.hasProductAccess) return
      try {
        const isPostCompleted = this.completedPostIds.has(this.activePost.id)
        // checking if video played time is greater then 0 sec
        if (
          this.videoTime.length &&
          this.videoTime[0] > 0 &&
          !isPostCompleted
        ) {
          const percentage = (this.videoTime[0] / this.videoTime[1]) * 100

          if (percentage >= 100) {
            this.togglePostCompletion()
            return
          }
          UserPostCompletionService.markPostAsCompleted({
            productId: this.productId,
            postId: this.activePost.id,
            percentage: percentage > 100 ? 100 : percentage,
          })
          this.videoCompletePercentage = percentage
        }
      } catch (error) {
        console.error('Failed to update video progress: ', error)
      }
    },

    updateAudioProgress() {
      if (this.isAdmin || !this.hasProductAccess) return
      try {
        const isPostCompleted = this.completedPostIds.has(this.activePost.id)
        // checking if video played time is greater then 0 sec
        if (
          this.audioTime.length &&
          this.audioTime[0] > 0 &&
          !isPostCompleted
        ) {
          const percentage = (this.audioTime[0] / this.audioTime[1]) * 100

          if (percentage >= 100) {
            this.togglePostCompletion()
            return
          }
          UserPostCompletionService.markPostAsCompleted({
            productId: this.productId,
            postId: this.activePost.id,
            percentage: percentage > 100 ? 100 : percentage,
          })
          this.audioCompletePercentage = percentage
        }
      } catch (error) {
        console.error('Failed to update video progress: ', error)
      }
    },
    storeVideoProgress() {
      if (!this.isVideoProgressUpdated) {
        this.updateVideoProgress()
        this.isVideoProgressUpdated = true
      }
    },
    updateVideoPlay() {
      this.isVideoProgressUpdated = false
    },
    updateVideoTime(currentTime: Array<number>) {
      this.videoTime = currentTime
    },
    storeAudioProgress() {
      if (!this.isAudioProgressUpdated) {
        this.updateAudioProgress()
        this.isAudioProgressUpdated = true
      }
    },
    updateAudioPlay() {
      this.$emit('updateAudioPlay')
    },
    updateAudioTime(currentTime: Array<number>) {
      this.audioTime = currentTime
    },
    updateBackData(params) {
      this.$emit('updateBackData', params)
    },
  },
  watch: {
    async postId(oldValue, newValue) {
      if (oldValue === newValue) return
      this.resetData()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
      await this.fetchPost(newValue)
      await Promise.all([
        this.checkIfPostCompleted(newValue),
        this.fetchCompletionData(newValue),
      ])
      await this.handleLocking()
    },
    async categoryId(newValue) {
      this.resetData()
      await Promise.all([
        this.fetchCategoryAndPosts(newValue),
        this.fetchNextCategory(newValue),
      ])
    },
  },
})
</script>

<style>
.custom-list-styling ul,
.custom-list-styling ol {
  padding-left: 40px;
}
</style>
