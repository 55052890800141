<template>
  <div>
    <div
      class="default-post h-auto bg-no-repeat bg-cover bg-center bg-gray-300 hero-image w-full flex justify-center items-center"
      id="banner"
      :style="
        !heroCustomBackground && {
          backgroundImage: `url(${replaceBucketUrlWithCdnUrl(
            heroSection.background
          )})`,
        }
      "
      :class="heroSection.alignment"
    >
      <div
        :style="
          !heroCustomBackground &&
          `background-color: ${heroSection.overlayColor}`
        "
        class="overlay"
      ></div>
      <div
        id="hero-section"
        class="hero-content xl:px-24 lg:x-16 md:px-12 px-6 w-full"
        :class="heroSpacing || heroSection.spacing"
      >
        <div v-if="showLogo" class="flex justify-center items-center">
          <img
            v-if="brandLogoUrl"
            class="brand-logo h-10 w-auto lg:h-12 md:h-12"
            :src="replaceBucketUrlWithCdnUrl(brandLogoUrl)"
            alt="Logo"
          />
        </div>
        <div
          id="product-title"
          class="font-bold text-4xl"
          :class="
            $route.name.includes('product-overview')
              ? 'primaryStyle hero-title'
              : ''
          "
        >
          {{ product.title || 'Fetching Product..' }}
        </div>
        <div
          id="product-description"
          v-if="product.description"
          class="font-sans text-xl py-2"
          :class="
            $route.name.includes('product-overview')
              ? 'secondaryStyle hero-description'
              : ''
          "
        >
          {{ product.description }}
        </div>
        <div
          id="product-status"
          class="mt-8"
          :class="
            heroButton && heroButton.textAlign
              ? `text-${heroButton.textAlign}`
              : 'text-center'
          "
        >
          <div
            id="completion-message"
            class="font-semibold text-2xl text-white"
            v-if="courseCompleted"
          >
            Congrats!! You have completed this course!
          </div>
          <div
            v-if="
              !courseCompleted && !(nextPost && nextPost.id) && hasProductAccess
            "
            id="partial-completion-message"
            class="font-semibold text-xl text-white"
          >
            You seem to have completed all available contents!!
          </div>
          <button
            id="load-next-post"
            v-if="nextPost && nextPost.id && hasProductAccess"
            @click="handleNextPostClick()"
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 focus:outline-none transition duration-150 ease-in-out"
            :class="
              $route.name.includes('product-overview')
                ? 'primary-hero-button secondaryStyle hero-button'
                : ''
            "
          >
            {{ buttonText }}
          </button>
          <div
            v-if="!hasProductAccess"
            class="text-center flex flex-col items-center justify-center"
          >
            <Lock01Icon class="h-20 w-20" />
            <UITextLgMedium>
              {{ productAccessMessage }}
            </UITextLgMedium>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div
      id="product-details-container"
      class="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-5 gap-4 my-10"
    >
      <div class="lesson-stats-instructor-container col-span-3 md:col-span-2">
        <LessonsStats
          :completed="completedPosts"
          :total="totalPosts"
          :progress-color="progressColor"
          class="mb-3"
        />
        <InstructorCard :instructor="instructor" />
      </div>
      <div
        class="category-post-list-container bg-white shadow col-span-3 course-background"
      >
        <div class="h-screen overflow-y-scroll course-section">
          <!-- <CategoryPostList :categories="categories" :completed-posts="postIdsCompleted" /> -->
          <CategoryPostTree
            :level="0"
            :category-tree="categoryTree"
            :completed-posts="postIdsCompleted"
            :completed-categories="categoryIdsCompleted"
            :isBackground="false"
            :hasProductAccess="hasProductAccess"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import CategoryPostTree from '@/components/product/CategoryPostTree.vue'
import LessonsStats from '@/components/product/LessonsStats.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import { Lock01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UITextLgMedium } from '@gohighlevel/ghl-ui'
import { getProductAccessMessage } from '@/helper/offerAccess'
import {
  getHeroSpacingClass,
  getThemeSettings,
  getSections,
  getProgressBarColor,
} from '@/helper/index'

export default defineComponent({
  components: {
    CategoryPostTree,
    LessonsStats,
    InstructorCard,
    UITextLgMedium,
    Lock01Icon,
  },
  props: {
    heroSection: {
      type: Object,
    },
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    categoryTree: {
      type: Array,
    },
    nextPost: {
      type: Object,
    },
    completedPosts: Number,
    totalPosts: Number,
    postIdsCompleted: Set,
    categoryIdsCompleted: Set,
    courseCompleted: Boolean,
    productStarted: Boolean,
    logo: String,
    hasProductAccess: {
      type: Boolean,
    },
  },
  data() {
    return {
      styles: '',
      themeSettings: {},
      sections: {},
      progressColor: '',
    }
  },
  mounted() {
    this.themeSettings = getThemeSettings(this.product)
    this.sections = getSections(this.product)
    this.progressColor = getProgressBarColor(this.product)
  },
  computed: {
    productAccessMessage() {
      return getProductAccessMessage(this.hasProductAccess, this.product)
    },
    brandLogoUrl(): string {
      return this.logo || this.$store.state.logoUrl
    },
    hero(): any {
      return this.sections?.hero
    },
    heroCustomBackground(): boolean {
      const background = this.hero?.background?.background
      if (background) {
        return true
      }
      return false
    },
    heroButton(): any {
      return this.hero?.button
    },
    buttonContent(): any {
      return this.heroButton?.content
    },
    showLogo(): boolean {
      if (this.themeSettings) {
        return this.themeSettings.logoPlacement === 'hero'
      }
      return false
    },
    heroSpacing(): string {
      if (this.sections?.hero?.background?.spacing) {
        return getHeroSpacingClass(this.sections.hero.background.spacing)
      }
      return null
    },
    buttonText() {
      if (this.productStarted) {
        return this.totalPosts === this.completedPosts
          ? 'Go to Course'
          : 'Resume Course'
      }
      return this.buttonContent || 'Start Course'
    },
  },
  methods: {
    handleNextPostClick() {
      this.$emit('goToNextPost')
    },
  },
})
</script>
<style scoped>
#load-next-post:hover {
  opacity: 0.8;
}
#banner {
  min-height: calc((100vw / 4.57));
  z-index: auto;
}
</style>
