
import { defineComponent } from 'vue'
import Navigation from '@/components/common/Navigation.vue'
import Product from '../models/Product'
import {
  ProductService,
  UserPurchaseService,
  UserProductTrackingService,
  UserPostCompletionService,
  getUserId,
} from '../services'
import { Instructor } from '../models/ProductCustomization'
import {
  extractInstructorInfoFromCustomization,
  getThemeClasses,
  isClientPortal,
  sanitizeJavaScriptCode,
  createElement,
} from '@/helper'
import { userAvatarImage, SOURCES } from '../helper/constants'
import postscribe from 'postscribe'
import UniversalSearch from '@/components/common/UniversalSearch.vue'
import { adminMode } from '@/helper/permission.helper'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { isPwa } from '@gohighlevel/clientportal-core'

const __default__ = defineComponent({
  metaInfo() {
    const meta = {}
    const metaLink = []

    if (this.productFavicon) {
      metaLink.push({
        vmid: 'favicon',
        rel: 'shortcut icon',
        href: replaceBucketUrlWithCdnUrl(this.productFavicon),
      })
    }

    if (metaLink.length) {
      meta['link'] = [...metaLink]
    }
    return meta
  },
  components: {
    Navigation,
    UniversalSearch,
    //AppLoader,
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    disableActions(): any {
      const inPreviewMode = !!this.$route.query.builder
      return {
        pointerEvents: inPreviewMode ? 'none' : 'all',
      }
    },
    isAdmin() {
      return adminMode()
    },
    templateId(): string {
      return this.$route.query.template_id || null
    },
    newMobileScreens() {
      return this.$store.getters.newMobileScreens
    },
    selectedTheme(): string {
      if (this.$route.query.template_id) {
        return this.$route.query.template_id
      }
      if (this.newMobileScreens && !this.$route.query.builder) {
        return 'NewYork'
      }
      let template = 'Default'
      if (this.$route.name.includes('post-overview')) {
        template = 'Classic'
      }
      if (this.product?.customizations?.templateId) {
        template = this.product.customizations?.templateId
      }
      return template
    },
    headerAlignment() {
      if (
        this.$route.name.includes('product-overview') &&
        this.sections?.header?.alignment
      ) {
        return this.sections.header.alignment
      } else if (
        this.$route.name.includes('post-overview') &&
        this.lessonSections?.header?.alignment
      ) {
        return this.lessonSections.header.alignment
      }
      return 'end'
    },
    instructor(): Instructor {
      if (this.product && this.product.customizations) {
        return extractInstructorInfoFromCustomization(
          this.product.customizations
        )
      }

      return {
        heading: 'Instructor',
        name: 'Instructor',
        title: 'Instructor',
        bio: 'Lorem ipsum',
        headshot: userAvatarImage,
      } as Instructor
    },
    isCpAppInstalled(): boolean {
      return this.$store.getters.appFlags.isCpAppInstalled
    },
    containerHeight() {
      return this.isCpAppInstalled ? '3.125rem' : '4rem'
    },
    getThemeClasses() {
      return getThemeClasses
    },
    isClientPortal(): boolean {
      return isClientPortal()
    },
    isCoursesCustomCodeExecutable() {
      return (
        this.$store.getters.appFlags.isLegacyMobileBrowser ||
        this.$store.getters.appFlags.isLegacyPWA ||
        this.$store.getters.appFlags.isLegacyWeb ||
        this.$store.getters.appFlags.isClientPortalWeb
      )
    },
  },
  async beforeMount() {
    try {
      const productId = this.$route.params.id
      if (this.newMobileScreens && this.$route.query.source === 'communities') {
        this.$router.push({
          name: 'category-list',
          params: { id: productId },
          query: this.$route.query,
        })
      }
      console.log('checking if product is purchased --> ', productId)

      // Below API to throw 404 response if the product is not purchased and catch block will get executed
      await UserPurchaseService.checkIfProductBought(productId)
    } catch (error) {
      if (this.$route.query.source !== SOURCES.communities) {
        this.$router.push({ name: 'library-v2' })
      } else {
        const targetURL = `${window.location.origin}/communities/groups/${this.$route.query.group_slug}/learning`
        window.location.href = targetURL
      }
    }
  },
  async mounted() {
    try {
      this.fetching = true
      //appLoading(true)
      this.emitter.on('openSearchModal', this.openSearchModal)
      await Promise.all([
        this.loadProductData(this.productId),
        this.fetchAllCompletedPosts(),
      ])
      if (this.$route.query.template_id) {
        await this.loadProductThemeData(this.productId)
      }

      // Loading fonts to head: vue-meta doesn't handle reactive values
      this.loadFontsToDOM('fonts', this.fontsToLoad)
      this.loadFontsToDOM('lesson-fonts', this.lessonFontsToLoad)

      // Below code will add custom code in Web and Legacy courses which is coming from course details
      if (this.isCoursesCustomCodeExecutable) {
        this.loadCustomCode()
      }
    } catch (error) {
      console.error('Error while loading product container: ', error)
    } finally {
      this.fetching = false
      //appLoading(false)
    }
  },
  data() {
    return {
      product: {} as Product,
      headerSection: {
        logoImage: '',
      },
      productFavicon: '',
      fetching: false,
      productStarted: false,
      templateStyles: '',
      lessonTemplateStyles: '',
      headerLogoPlacement: true,
      fontsToLoad: '',
      lessonFontsToLoad: '',
      sections: {} as any,
      lessonSections: {} as any,
      showSearchModal: false,
      containerClass: {
        'product-overview': 'product-container',
        'post-overview': 'lesson-container',
      },
      routeClass: {
        'product-overview': 'productRoot',
        'post-overview': 'lessonRoot',
      },
      backCallbackData: {},
      allCompletedPostsData: {},
    }
  },
  methods: {
    loadCustomCode() {
      const { customCss, customJs } = this.product
      if (customJs) {
        this.addCustomJsTag(customJs)
      }
      if (customCss) {
        this.addCustomCssTag(customCss, 'product-custom-css')
      }

      // This is to add theme styles
      if (this.templateStyles) {
        this.addCustomCssTag(this.templateStyles, 'template-style')
      }
      if (this.lessonTemplateStyles) {
        this.addCustomCssTag(this.lessonTemplateStyles, 'lesson-template-style')
      }
    },
    loadFontsToDOM(vmid: string, fontUrl: string) {
      document.head.appendChild(
        createElement('link', [
          { type: 'rel', value: 'stylesheet' },
          { type: 'vmid', value: vmid },
          { type: 'href', value: fontUrl },
        ])
      )
    },
    addCustomJsTag(customJs: string) {
      document.head.appendChild(
        createElement('script', [], sanitizeJavaScriptCode(customJs))
      )
    },
    addCustomCssTag(cssText: string, vmid: string) {
      document.head.appendChild(
        createElement(
          'style',
          [
            { type: 'type', value: 'text/css' },
            { type: 'vmid', value: vmid },
          ],
          cssText
        )
      )
    },
    async fetchAllCompletedPosts() {
      const completedResp = await UserPostCompletionService.findAll({
        product_id: this.productId,
        user_id: getUserId(),
      })

      const completedPostIds = completedResp.reduce(
        (agg: Set<string>, completed: any) => {
          const { postId, percentage } = completed
          if (percentage && percentage === 100) agg.add(postId)
          return agg
        },
        new Set()
      )
      this.allCompletedPostsData = {
        completedPostIds: completedPostIds,
        posts: [...completedResp],
      }
    },
    updateCompletedPostData(newPostIds: Set<string>, newPosts: Array<object>) {
      this.allCompletedPostsData = {
        ...this.allCompletedPostsData,
        ...(newPosts ? { posts: newPosts } : {}),
        ...(newPostIds ? { completedPostIds: newPostIds } : {}),
      }
    },
    async loadProductData(productId: string) {
      await this.fetchProduct(this.productId)
      await this.markProductLogin(this.productId)
      this.productStarted = await this.getProductStartStatus()
    },
    async fetchProduct(productId: string) {
      try {
        this.product = await ProductService.findById(productId)
        const customization = this.product.customizations

        if (customization && customization.favicon) {
          this.productFavicon = customization.favicon
        }

        if (customization && customization.logoImage) {
          this.headerSection.logoImage = customization.logoImage
        }

        if (customization && !this.templateId) {
          if (customization.settings) {
            this.templateStyles = customization.settings.styles
            const headerLogo =
              customization.settings?.themeSettings?.logoPlacement || 'header'
            this.headerLogoPlacement = headerLogo === 'header'
            if (customization.settings.fontsToLoad) {
              this.fontsToLoad = customization.settings.fontsToLoad
            }
            if (customization.settings.sections) {
              this.sections = customization.settings.sections
            }
          }
          if (customization.lessonSettings) {
            this.lessonTemplateStyles = customization?.lessonSettings?.styles
            if (customization.lessonSettings.fontsToLoad) {
              this.lessonFontsToLoad = customization.lessonSettings.fontsToLoad
            }
            if (customization.lessonSettings.sections) {
              this.lessonSections = customization.lessonSettings.sections
            }
          }
        }

        if (this.product.customHeader) {
          postscribe(
            `#productCustomHeader`,
            createElement('script', [], this.product.customHeader).outerHTML
          )
        }

        if (this.product.customFooter) {
          postscribe(
            `#productCustomFooter`,
            createElement('script', [], this.product.customFooter).outerHTML
          )
        }
      } catch (error) {
        console.error('Error while fetching product --> ', error)
      }
    },
    async markProductLogin(productId: string) {
      try {
        if (!productId || this.isAdmin) return
        await UserProductTrackingService.trackProductLogin(productId)
      } catch (error) {
        console.error('Error while tracking user product login --> ', error)
      }
    },
    async getProductStartStatus(): Promise<boolean> {
      try {
        if (this.isAdmin) return false
        const { started } =
          await UserProductTrackingService.checkIfProductStarted(this.productId)
        return started
      } catch (error) {
        console.error('error while checking product started --> ', error)
        return false
      }
    },
    async loadProductThemeData(productId) {
      const productThemeData = await ProductService.getCustomizationSettings(
        productId,
        { template_id: this.templateId }
      )
      if (productThemeData?.settings) {
        this.templateStyles = productThemeData?.settings?.styles
        this.lessonTemplateStyles = productThemeData?.lessonSettings?.styles
        const headerLogo =
          productThemeData.settings?.themeSettings?.logoPlacement || 'header'
        this.headerLogoPlacement = headerLogo === 'header'
        if (productThemeData.settings.fontsToLoad) {
          this.fontsToLoad = productThemeData.settings.fontsToLoad
        }
        if (productThemeData?.lessonSettings?.fontsToLoad) {
          this.lessonFontsToLoad = productThemeData.lessonSettings.fontsToLoad
        }
        if (productThemeData?.settings?.sections) {
          this.sections = productThemeData.settings.sections
        }
      }
      if (this.product && this.product.customizations) {
        this.product.customizations.settings = productThemeData.settings
        this.product.customizations.lessonSettings =
          productThemeData.lessonSettings
      }

      if (productThemeData?.lessonSettings?.sections) {
        this.lessonSections = productThemeData.lessonSettings.sections
      }
    },
    openSearchModal(value: boolean) {
      this.showSearchModal = value
    },
    updateBackData(data) {
      this.backCallbackData = data
    },
    backCallback() {
      if (this.backCallbackData.callback) {
        this.backCallbackData.callback()
        return
      }
      this.$router.push(this.backCallbackData)
    },
  },
  watch: {
    productId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.loadProductData(newValue)
      }
    },
    newMobileScreens(newValue, oldValue) {
      if (newValue) {
        this.$router.push({
          name: 'category-list',
          id: this.productId,
          query: this.$route.query,
        })
      }
    },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3ff9c15a": (_ctx.containerHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__