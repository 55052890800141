import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "app-container" }
const _hoisted_2 = {
  key: 0,
  class: "w-full h-screen flex items-center justify-center"
}
const _hoisted_3 = {
  key: 1,
  class: "fixed z-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_UISpinner = _resolveComponent("UISpinner")!
  const _component_UIContentWrap = _resolveComponent("UIContentWrap")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MobileFloatingNavigation = _resolveComponent("MobileFloatingNavigation")!
  const _component_BottomNavBar = _resolveComponent("BottomNavBar")!
  const _component_MobileAlertModal = _resolveComponent("MobileAlertModal")!
  const _component_PaymentRedirectionConfirmation = _resolveComponent("PaymentRedirectionConfirmation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_metainfo),
    (_ctx.fetching && !_ctx.isCpAppInstalled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_UIContentWrap, { fullScreen: true }, {
            default: _withCtx(() => [
              _createVNode(_component_UISpinner, { size: "extra-large" })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.isAppLoading() && _ctx.isCpAppInstalled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AppLoader)
        ]))
      : (_openBlock(), _createBlock(_component_UIContentWrap, {
          key: 2,
          class: "route-container",
          fullScreen: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, { onUpdateLoginStatus: _ctx.checkLoginStatus }, null, 8, ["onUpdateLoginStatus"])
          ]),
          _: 1
        })),
    (!_ctx.fetching && _ctx.newMobileScreens && !_ctx.isCpAppInstalled)
      ? (_openBlock(), _createBlock(_component_MobileFloatingNavigation, { key: 3 }))
      : (_ctx.newMobileScreens && _ctx.isCpAppInstalled && _ctx.showCpBottomNav)
        ? (_openBlock(), _createBlock(_component_BottomNavBar, {
            key: 4,
            "navigation-items": _ctx.navigation.state.navigationItems,
            class: "cols-span-5 fixed bottom-0 z-40 w-full justify-center text-gray-900"
          }, null, 8, ["navigation-items"]))
        : _createCommentVNode("", true),
    (_ctx.newMobileScreens && _ctx.isLoggedIn && !_ctx.isClientPortal() && _ctx.localPWAFlag)
      ? (_openBlock(), _createBlock(_component_MobileAlertModal, {
          key: 5,
          "pwa-alert-data": _ctx.pwaAppSettings,
          isLoggedIn: _ctx.isLoggedIn
        }, null, 8, ["pwa-alert-data", "isLoggedIn"]))
      : _createCommentVNode("", true),
    (!_ctx.fetching)
      ? (_openBlock(), _createBlock(_component_PaymentRedirectionConfirmation, { key: 6 }))
      : _createCommentVNode("", true)
  ]))
}